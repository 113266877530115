import React, { cloneElement, useMemo } from "react";
import classnames from "classnames";
import * as Core from "@blueprintjs/select";
import { InputGroup as CoreInputGroup, PopoverProps } from "@blueprintjs/core";
import { Search } from "@remhealth/icons";
import type { DataAttributes } from "~/types";
import { DefaultNoResults, ItemListRenderer, ItemListRendererProps, ListGroupRenderer, extendItemListRendererProps, isIntrinsicElement, mapChildrenDeep } from "../utils";
import { useAutomation } from "../hooks";
import { Menu, MenuProps } from "./menu";
import { usePopperModifiers } from "./popover";
import { FormScope, getComponentId } from "./formScope";
import { InputGroup, InputGroupProps } from "./inputGroup";
import { ListItemsProps, SelectPopoverProps, useListItems } from "./useListItems";
import { useSelectMenuProps } from "./useSelectMenuProps";

type PropOmissions =
  | "items"
  | "menuProps"
  | "popoverProps"
  | "itemRenderer"
  | "itemListRenderer"
  | "resetOnClose"
  | "resetOnQuery"
  | "resetOnSelect";

export interface SelectProps<T> extends Omit<Core.SelectProps<T>, PropOmissions | keyof ListItemsProps<T>>, ListItemsProps<T>, DataAttributes {
  items: ReadonlyArray<T>;

  /** Whether the menu items in the select menu should use a large appearance. */
  large?: boolean;

  id?: string;

  "aria-label"?: string;

  menuProps?: MenuProps;

  groupRenderer?: ListGroupRenderer<T, ItemListRendererProps<T>>;

  itemListRenderer?: ItemListRenderer<T>;

  popoverProps?: SelectPopoverProps;
}

export function Select<T>(props: SelectProps<T>) {
  const {
    itemsEqual,
    itemListRenderer = defaultItemListRenderer,
    infoRenderer,
    optionRenderer,
    groupRenderer,
    initialContent,
    noResults = DefaultNoResults,
    large,
    filterable = true,
    popoverProps = {},
    items,
    inputProps: controlledInputProps,
    menuProps: controlledMenuProps,
    popoverTargetProps,
    ...restProps
  } = props;

  const { itemPredicate, itemRenderer } = useListItems(props, [groupRenderer]);

  const { minimal = true, width, maxWidth, maxHeight, matchTargetWidth, ...restPopoverProps } = popoverProps;

  const { label, id } = useAutomation(props);
  const filterId = getComponentId(id, "filter");
  const popoverId = getComponentId(id, "popover");

  const [modifiers, modifiersCustom, popperPopoverTargetProps] = usePopperModifiers({
    label,
    popoverId,
    flowTo: id,
    modifiers: restPopoverProps.modifiers,
    modifiersCustom: restPopoverProps.modifiersCustom,
  });

  const sortedItems = useMemo(() => groupRenderer?.([...items]).flatMap(i => i.items) ?? [...items], [items, groupRenderer]);

  const menuProps = useSelectMenuProps({
    label: `${label ?? "Select List"} Items`,
    id,
    maxWidth,
    maxHeight,
    width,
    matchTargetWidth,
  });

  const inputProps = useMemo(() => ({
    "aria-label": "Select Filter",
    ...controlledInputProps,
  }), [controlledInputProps]);

  // Fix issue with undefined acting like controlled value
  if (restPopoverProps && restPopoverProps.isOpen === undefined) {
    delete restPopoverProps.isOpen;
  }

  return (
    <ExtendedSelect<T>
      {...restProps}
      filterInputProps={{
        "aria-label": label ? `${label} filter` : undefined,
        "id": filterId,
      }}
      filterable={filterable && items.length >= 8}
      initialContent={initialContent}
      inputProps={inputProps}
      itemListRenderer={renderList}
      itemPredicate={itemPredicate}
      itemRenderer={itemRenderer}
      items={sortedItems}
      itemsEqual={itemsEqual}
      menuProps={menuProps}
      noResults={noResults}
      popoverProps={{
        targetTagName: "div",
        positioningStrategy: "fixed",
        ...restPopoverProps,
        minimal,
        portalClassName: classnames(restPopoverProps?.portalClassName, "no-overflow"),
        matchTargetWidth,
        modifiers,
        modifiersCustom,
      }}
      popoverTargetProps={{
        ...popoverTargetProps,
        ...popperPopoverTargetProps,
        "aria-controls": menuProps.id,
        id,
      }}
      resetOnClose={false}
      resetOnQuery={false}
      resetOnSelect={false}
    />
  );

  function renderList(listProps: Core.ItemListRendererProps<T>) {
    const extendedProps = extendItemListRendererProps<T, ItemListRendererProps<T>>(listProps, groupRenderer, noResults, initialContent);
    extendedProps.menuProps = { ...extendedProps.menuProps, ...menuProps };
    return (
      <FormScope controlId="listbox">
        {itemListRenderer(extendedProps)}
      </FormScope>
    );
  }

  function defaultItemListRenderer(listProps: ItemListRendererProps<T>) {
    const createItem = listProps.renderCreateItem();
    const maybeNoResults = !createItem && listProps.filteredItems.length === 0 ? noResults : null;
    const menuContent = listProps.renderItems(maybeNoResults, initialContent);

    if (!menuContent && !createItem) {
      return null;
    }

    return (
      <Menu {...listProps.menuProps} {...menuProps} ulRef={listProps.itemsParentRef}>
        {menuContent}
        {createItem}
      </Menu>
    );
  }
}

type ExtendedSelectProps<T> = Core.Select<T>["props"]
  & { filterInputProps?: InputGroupProps };

class ExtendedSelect<T> extends Core.Select<T> {
  public declare props: ExtendedSelectProps<T>;

  constructor(props: ExtendedSelectProps<T>) {
    super(props);
  }

  public render() {
    const element = super.render();
    return cloneElement<Core.QueryListProps<T>>(element, {
      renderer: this.queryListRenderer(element.props.renderer),
    });
  }

  private queryListRenderer(renderer: Core.QueryListProps<T>["renderer"]) {
    return (listProps: Core.QueryListRendererProps<T>) => {
      const popover = renderer(listProps) as React.ReactElement<PopoverProps>;

      if (isIntrinsicElement(popover.props.content, "div")) {
        return cloneElement(popover, {
          content: mapChildrenDeep(popover.props.content, [CoreInputGroup], child => {
            return (
              <InputGroup
                aria-label="Filter"
                {...child.props}
                leftIcon={<Search />}
                type="text"
                {...this.props.filterInputProps}
                onChange={undefined}
                onUnbufferedChange={e => e && child.props.onChange?.(e)}
              />
            );
          }),
        });
      }
      return popover;
    };
  }
}
